import { Container, Main, Icons, LinkComponent} from '../../components'
import {useState, useEffect, useContext  } from 'react'
import styles from './styles.module.css'
import Description from './description'
import { UserContext } from '../../contexts'
import { useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAbout } from '../../utils/index.js'
import api from '../../api'

const SingleCardPost = ({ loadItem, updateOrders }) => {
  const [ loading, setLoading ] = useState(true)
  const {
    about,
    setAbout,
  } = useAbout()
  const { id } = useParams()
  const history = useHistory()
  const userContext = useContext(UserContext)


  useEffect(_ => {
    api.getAbout ({
        about_id: id
      })
      .then(res => {
        setAbout(res)
        setLoading(false)
      })
      .catch(err => {
        history.push('/about')
      })
  }, [])
  
  const { url } = useRouteMatch()
  const {
    image,
    theme,
    text,
    date_posted
  } = about

  const formattedDate = new Date(date_posted).toLocaleDateString('ru-RU');
  
  return <Main>
  <Container>
    <Helmet>
      <title>{theme}</title>
      <meta name="description" content={`клуб Дзюдо Уняшка - ${theme}`} />
      <meta property="og:title" content={theme} />
    </Helmet>
    <div className={styles['single-card']}>
      <img src={image} alt={theme} className={styles["single-card__image"]} />
      <div className={styles["single-card__info"]}>
        <div className={styles["single-card__header-info"]}>
            <h1 className={styles["single-card__title"]}>{theme}</h1>
        </div>
        <div>
        <p className={styles['single-card__text']}><Icons.ClockIcon /> {formattedDate}</p>
          <p className={styles['single-card__text_with_link']}>
            <div className={styles['single-card__text']}>
            </div>
            {userContext && (userContext.is_staff || userContext.is_superuser) && (<LinkComponent
              href={`${url}/edit`}
              title='Редактировать новость'
              className={styles['single-card__edit']}
            />)}
          </p>
        </div>
        <Description description={text} />
      </div>
  </div>
  </Container>
</Main>
}

export default SingleCardPost

