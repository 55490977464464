import { Title, Pagination, Container, Main, CheckboxGroupAlbum, CardListGallary ,CardGallary,  } from '../../components'
import React, { useState, useEffect } from 'react';
import styles from './styles.module.css'
import Slideshow from './Slideshow';
import api from '../../api'
import { useGallarys } from '../../utils/index.js'
import { Helmet } from 'react-helmet';

const ImageGallery = ({ updateOrders }) => { 
  const [selectedImage, setSelectedImage] = useState(null); 
  const [showSlideshow, setShowSlideshow] = useState(false); 
 
  const {
    gallarys,
    setGallarys,
    gallarysCount,
    setGallarysCount,
    gallarysPage,
    setGallarysPage,
    albumsValue,
    handleAlbumsChange,
    setAlbumsValue
  } = useGallarys()

  const getGallarys = ({ page = 1, albums }) => {
    api
      .getGallarys({ page, albums })
      .then(res => {
        const { results, count } = res
        setGallarys(results)
        setGallarysCount(count)
      })
  }

  useEffect(_ => {
    getGallarys({ page: gallarysPage, albums: albumsValue })
  }, [gallarysPage, albumsValue])

  useEffect(_ => {
    api.getAlbums()
      .then(albums => {
        setAlbumsValue(albums.map(album => ({ ...album, value: true })))
      })
  }, [])
 
  const handleImageClick = (image) => { 
    setSelectedImage(image); 
    setShowSlideshow(true);  }; 
  
  const closeSlideshow = () => { 
    setSelectedImage(null); 
    setShowSlideshow(false);  }; 
  
  return (
    <Main> 
      <Container> 
        <Helmet> 
          <title>Фотогалерея</title> 
          <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" /> 
          <meta property="og:title" content="Фотогалерея" /> 
        </Helmet> 
        <div className={styles.title}> 
          <Title title='Фотогалерея' className={styles.title1} /> 
          <CheckboxGroupAlbum
          values={albumsValue}
          handleChange={value => {
            setGallarysPage(1)
            handleAlbumsChange(value)
          }}
        />
       <div> 
          <CardListGallary> 
            {gallarys && gallarys.map(cardGallary => <CardGallary
              {...cardGallary}
              key={cardGallary.id}
              handleImageClick={handleImageClick} 
              updateOrders={updateOrders}
            />)}
          </CardListGallary> 
      {showSlideshow && ( 
        <Slideshow 
          images={gallarys} 
          selectedImage={selectedImage} 
          setSelectedImage={setSelectedImage} 
          closeSlideshow={closeSlideshow}        
          />      
          )}    
          </div>  
          </div>
          <Pagination
            count={gallarysCount}
            limit={6}
            page={gallarysPage}
            onPageChange={page => setGallarysPage(page)}
          />
        </Container> 
      </Main>
)}; 

export default ImageGallery; 