import React, { useState } from 'react';
import {  useHistory} from "react-router-dom";
import styles from './styles.module.css'
import {
      Title,
      Container,
      Main,
      Input,
      Form,
      Button, Footer
    } from '../../components'
import { Helmet } from 'react-helmet'
import axios from 'axios'

function PasswordReset() { 
  const [email, setEmail] = useState(''); 
  const history = useHistory(); 
  const [resetSuccess, setResetSuccess] = useState(false); 
  const [error, setError] = useState('');

  axios.defaults.withCredentials = true; 

  const handleSubmit = (e) => { 
    e.preventDefault(); 
     axios.post('/api/users/reset_password/', { email }) 
      .then(res => { 
        if (res.status === 204) {   
          setResetSuccess(true); 
        } else { 
          setError(res.data.detail); 
        }   
      })
      .catch(error => { 
        setError('Произошла ошибка сети. Попробуйте позже.'); 
      }); 
  };

  return (
    <Main>
    <Container>
      <Helmet>
        <title>Сброс пароля</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Сброс пароля" />
        <meta property="og:title" content="Сброс пароля" />
      </Helmet>
      <Title title="Сброс пароля" />
        <Form 
        className={styles.form}
        onSubmit={handleSubmit}>
        {resetSuccess ? (
        <p>Ссылка для сброса пароля была отправлена на ваш email</p>
      ) : (
        <>
        {error && <p>{error}</p>}
          <p>Отправить ссылку на сброс пароля</p>
          {error && <p style={{ color: 'red' }}>{error}</p>}
            <Input 
              label='Введите Ваш Email' 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <Button 
            className={styles.button}
            modifier='style_dark-blue' 
            type="submit">Отправить письмо</Button>
        </>
      )}
          </Form>
     </Container>
     <Footer />
      </Main>
    );
  };

export default PasswordReset;
