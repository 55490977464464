import { CardPost, Title, Pagination, CardListPost, Container, Main } from '../../components'
import styles from './styles.module.css'
import { useAbouts } from '../../utils/index.js'
import { useEffect } from 'react'
import api from '../../api'
import { Helmet } from 'react-helmet'

const Posts = ({ updateOrders }) => {
  const {
    abouts,
    setAbouts,
    aboutsCount,
    aboutsPage,
    setAboutsCount,
    setAboutsPage,
    handleAddToCart
  } = useAbouts()
  
  const getAbouts = ({ page = 1 }) => {
    api
      .getAbouts({ page })
      .then(res => {
        const { results, count } = res
        setAbouts(results)
        setAboutsCount(count)
      })
  }
  useEffect(_ => {
    getAbouts({ page: aboutsPage })
  }, [aboutsPage])

  return <Main>
    <Container>
      <Helmet>
        <title>Новости</title>
        <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" />
        <meta property="og:title" content="О нас" />
      </Helmet>
      <div className={styles.title}>
        <Title title='Новостная лента' />
      </div>
      <CardListPost>
        {abouts.map(cardPost => <CardPost
          {...cardPost}
          key={cardPost.id}
          updateOrders={updateOrders}
          handleAddToCart={handleAddToCart}
        />)}
      </CardListPost>
      <Pagination
        count={aboutsCount}
        limit={6}
        page={aboutsPage}
        onPageChange={page => setAboutsPage(page)}
      />
      <div className={styles.title}>
        <Title title='О нас' />
      </div>
      <div className={styles.text_line}>
        <p className={styles.centered_text}>
        Добро пожаловать в удивительный мир детского клуба, где каждый день встречаются забота, развитие и радость! 
        </p>
        <p>
        • Наш клуб Дзюдо засиял своим светом в далеком 1986 году и с каждым годом становится все более ярким и увлекательным! Начав как спортивный клуб для детей, мы выросли до настоящего центра раннего развития и активного образа жизни!
        </p>
        <p>
        • В 1996 году мы расширили свою деятельность, открыв детские группы раннего развития и распахнули двери нашего бассейна для самых маленьких. У нас занятия найдут не только дети от 4-х месяцев до подростков 14 лет, но и заботливые мамы!
        </p>
        <p>
        • Наша главная цель - постоянное развитие и совершенствование! Почти каждый год мы радуем новыми программами и возможностями для наших участников.
        </p>
        <p>
        • Все занятия проводят высококвалифицированные тренеры-преподаватели с высшим образованием и богатым опытом работы с детьми. Мы гордимся каждым тренером - они не только профессионалы своего дела, но и настоящие волшебники, вдохновляющие детей на выдающиеся достижения! 
        </p>
        <p className={styles.gradient_text}>
        Присоединяйтесь к нашему клубу и откройте для вашего ребенка мир ярких эмоций, удивительных открытий и теплой атмосферы дружбы! 
        </p>
      </div> 
    </Container>
  </Main>
}

export default Posts

