import styles from './style.module.css'
import { Container } from '../index'
import { Helmet } from 'react-helmet';

const Footer = () => {
  return <footer className={styles.footer}>
      <Container className={styles.footer__container}>
      <Helmet>
        <title></title>
        <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" />
        <meta property="og:title" content="Данные" />
      </Helmet>
        <a href="tel:+79217891165" target="_blank" rel="noopener noreferrer"><div className={styles.footer__nomber}>+7 (921) 789-11-65</div></a>
        <div><a className={styles.footer__adress}>м.Автово ул.Маринеско д.4</a></div>
        <div><a className={styles.footer__text2}>unyashka-dzudo@yandex.ru</a></div>
      </Container>
  </footer>
}

export default Footer
