import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import styles from './styles.module.css'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import {
  Title,
  Container,
  Icons,
  Main,
  Input,
  Form,
  Button
} from '../../components'

function PasswordResetConfirm () { 
  const [newPassword, setNewPassword] = useState(); 
  const { uid, token } = useParams(); 
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [Success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
 
  const handleSubmit = (e) => { 
    e.preventDefault(); 
    if (!newPassword.match(/[a-zA-Z]+/)) {
      setError('Пароль должен содержать хотя бы одну букву');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Пароли не совпадают');
      return;
    }
    const data = {
      uid: uid,
      token: token,
      new_password: newPassword
    };
    axios.post(`/api/users/reset_password_confirm/`, data)
    .then(res => { 
      if (res.status === 204) { 
        setSuccess(true); 
      } 
  }) 
  .catch(error => { 
    setError('Произошла ошибка при смене пароля. Попробуйте позже.'); 
  }); 
} 
const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

return (
  <Main>
    <Container>
      <Helmet>
        <title>Сброс пароля</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Сброс пароля" />
        <meta property="og:title" content="Сброс пароля" />
      </Helmet>
      <Title title="Сброс пароля" />
      <Form className={styles.form} onSubmit={handleSubmit}>
      {Success ? (
        <p>Пароль успешно изменен</p>
      ) : (
        <>
        {error && <p>{error}</p>}
          <Input
            required
            label='Введите новый пароль'
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <div className={styles.inputContainer}>
          <Input
            required
            label='Подтвердите новый пароль' 
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Icons.Eyeicon /> : <Icons.EyeLineicon />}
          </button>
          </div>
          {error && <p>{error}</p>}
          <Button className={styles.button} modifier='style_dark-blue' type="submit">
            Изменить пароль
          </Button>
          </>
          )}
      </Form>
    </Container>
  </Main>
);
}

export default PasswordResetConfirm;