import { Container, Input, Title, Main, Icons, Form, Button, LinkComponent } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import { Redirect } from 'react-router-dom'
import { useContext , useState} from 'react'
import { Helmet } from 'react-helmet'

const SignIn = ({ onSignIn }) => {
  const { values, handleChange, errors, isValid, resetForm } = useFormWithValidation()
  const authContext = useContext(AuthContext)
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return <Main>
    {authContext && <Redirect to='/lessons' />}
    <Container>
      <Helmet>
        <title>Войти на сайт</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Войти на сайт" />
        <meta property="og:title" content="Войти на сайт" />
      </Helmet>
      <Title title='Войти на сайт' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          onSignIn(values)
        }}
      >
        <Input
          required
          label='Электронная почта'
          name='email'
          onChange={handleChange}
        />
        <div className={styles.inputContainer}>
          <Input
            required
            label='Пароль'
            type={showPassword ? 'text' : 'password'}
            name='password'
            onChange={handleChange}
          />
          <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            Показать пароль
            {showPassword ? <Icons.Eyeicon /> : <Icons.EyeLineicon />}
          </button>
        </div>
        <Button
          modifier='style_dark-blue'
          disabled={!isValid}
          type='submit'
          className={styles.buttons}
        >
          Добро пожаловать!
        </Button>
        <LinkComponent
          href={`/send_reset_password`}
          title='Забыли пароль?'
          className={styles.reset}
        />
      </Form>
    </Container>
  </Main>
}

export default SignIn
