import styles from './styles.module.css'

const Description4 = ({ description4 }) => {
  if (!description4) { return null }
  return <div className={styles.description}>
    <h3 className={styles['description__title']}>Номер телефона:</h3>
    <div className={styles['description__content']} dangerouslySetInnerHTML={{ __html: description4 }} />
  </div>
}

export default Description4

