import { Container, Main, Button, TagsContainer, Icons, LinkComponent} from '../../components'
import { UserContext, AuthContext } from '../../contexts'
import { useContext, useState, useEffect } from 'react'
import styles from './styles.module.css'
import Description from './description'
import { useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useLesson } from '../../utils/index.js'
import api from '../../api'

const SingleCard = ({ loadItem, updateOrders }) => {
  const [ loading, setLoading ] = useState(true)
  const {
    lesson,
    setLesson,
    handleLike,
  } = useLesson()
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const { id } = useParams()
  const history = useHistory()

  useEffect(_ => {
    api.getLesson ({
        lesson_id: id
      })
      .then(res => {
        setLesson(res)
        setLoading(false)
      })
      .catch(err => {
        history.push('/lessons')
      })
  }, [])
  
  const { url } = useRouteMatch()
  const {
    author = {},
    image,
    tags,
    lessons_time,
    name,
    text,
    is_favorited,
  } = lesson

  
  return <Main>
    <Container>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={`клуб Дзюдо Уняшка - ${name}`} />
        <meta property="og:title" content={name} />
      </Helmet>
      <div className={styles['single-card']}>
        <img src={image} alt={name} className={styles["single-card__image"]} />
        <div className={styles["single-card__info"]}>
          <div className={styles["single-card__header-info"]}>
              <h1 className={styles["single-card__title"]}>{name}</h1>
              {authContext && <Button
                modifier='style_none'
                clickHandler={_ => {
                  handleLike({ id, toLike: Number(!is_favorited) })
                }}
              >
                {is_favorited ? <Icons.StarBigActiveIcon /> : <Icons.StarBigIcon />}
              </Button>}
          </div>
          <TagsContainer tags={tags} />
          <div>
            <p className={styles['single-card__text']}><Icons.ClockIcon /> {lessons_time} мин.</p>
            <p className={styles['single-card__text_with_link']}>
              <div className={styles['single-card__text']}>
                <Icons.UserIcon /> <LinkComponent
                  title={`${author.first_name} ${author.last_name}`}
                  href={`/user/${author.id}`}
                  className={styles['single-card__link']}
                />
              </div>
              {userContext && (userContext.is_staff || userContext.is_superuser) && (<LinkComponent
              href={`${url}/edit`}
              title='Редактировать занятие'
              className={styles['single-card__edit']}
            />)}
            </p>
          </div>
          <Description description={text} />
        </div>
    </div>
    </Container>
  </Main>
}

export default SingleCard

