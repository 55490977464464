import styles from './styles.module.css'

const Description3 = ({ description3 }) => {
  if (!description3) { return null }
  return <div className={styles.description}>
    <h3 className={styles['description__title']}>Возраст ребенка:</h3>
    <div className={styles['description__content']} dangerouslySetInnerHTML={{ __html: description3 }} />
  </div>
}

export default Description3

