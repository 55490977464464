import { Container, FileInput, Input, Title, CheckboxGroup, Main, Form, Button, Textarea } from '../../components'
import styles from './styles.module.css'
import api from '../../api'
import { useEffect, useState, useContext } from 'react'
import { useTags } from '../../utils'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { UserContext } from '../../contexts'

const LessonCreate = ({ onEdit }) => {
  const { value, handleChange, setValue } = useTags()
  const [ lessonName, setLessonName ] = useState('')
  const history = useHistory()
  const [ lessonText, setLessonText ] = useState('')
  const [ lessonsTime, setLessonTime ] = useState('')
  const [ lessonFile, setLessonFile ] = useState(null)
  const userContext = useContext(UserContext)


  useEffect(_ => {
    api.getTags()
      .then(tags => {
        setValue(tags.map(tag => ({ ...tag, value: true })))
      })
  }, [])


  const checkIfDisabled = () => {
    return lessonText === '' ||
    lessonName === '' ||
    value.filter(item => item.value).length === 0 ||
    lessonsTime === '' ||
    lessonFile === '' ||
    lessonFile === null
  }

  useEffect(() => {
    if (!userContext || (!userContext.is_staff && !userContext.is_superuser)) {
      history.push('/lessons')
    }
  }, [userContext])

  return <Main>
    <Container>
      <Helmet>
        <title>Создание занятия</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Создание занятия" />
        <meta property="og:title" content="Создание занятия" />
      </Helmet>
      <Title title='Создание занятия' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          const data = {
            text: lessonText,
            name: lessonName, 
            tags: value.filter(item => item.value).map(item => item.id),
            lessons_time: lessonsTime,
            image: lessonFile
          }
          api
          .createLesson(data)
          .then(res => {
            history.push(`/lessons/${res.id}`)
          })
          .catch(err => {
            const { non_field_errors, lessons_time } = err
            if (non_field_errors) {
              return alert(non_field_errors.join(', '))
            }
            if (lessons_time) {
              return alert(`Время занятия: ${lessons_time[0]}`)
            }
            const errors = Object.values(err)
            if (errors) {
              alert(errors.join(', '))
            }
          })
        }}
      >
        <div className={styles.LessonsInp}>
          <Input
            label='Название занятия'
            onChange={e => {
              const value = e.target.value
              setLessonName(value)
            }}
          />
        </div>
        <CheckboxGroup
          label='Теги'
          values={value}
          className={styles.checkboxGroup}
          labelClassName={styles.checkboxGroupLabel}
          tagsClassName={styles.checkboxGroupTags}
          checkboxClassName={styles.checkboxGroupItem}
          handleChange={handleChange}
        />
        <div className={styles.lessonsTime}>
          <Input
            label='Время занятия'
            className={styles.LessonsTimeInput}
            classLable={styles.LessonsTimelable}
            onChange={e => {
              const value = e.target.value
              setLessonTime(value)
            }}
            value={lessonsTime}
          />
          <div className={styles.lessonsTimeUnit}>мин.</div>
        </div>
        <Textarea
          label='Описание занятия'
          onChange={e => {
            const value = e.target.value
            setLessonText(value)
          }}
        />
        <FileInput
          onChange={file => {
            setLessonFile(file)
          }}
          className={styles.fileInput}
          label='Загрузить фото'
        />
        <Button
          modifier='style_dark-blue'
          disabled={checkIfDisabled()}
          className={styles.button}
        >
          Создать занятие
        </Button>
      </Form>
    </Container>
  </Main>
}

export default LessonCreate
