import  { useState } from "react";

export default function useAbout () {
  const [ about, setAbout ] = useState({})

  return {
    about,
    setAbout,
  }
}
