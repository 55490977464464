import { useState } from "react";

export default function useAbouts () {
  const [ abouts, setAbouts ] = useState([])
  const [ aboutsCount, setAboutsCount ] = useState(0)
  const [ aboutsPage, setAboutsPage ] = useState(1)

  return {
    abouts,
    setAbouts,
    aboutsCount,
    setAboutsCount,
    aboutsPage,
    setAboutsPage,

  }
}
