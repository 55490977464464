import styles from './style.module.css'
import cn from 'classnames'
import { LinkComponent} from '../index'
import navigation from '../../configs/navigation'
import { UserContext } from '../../contexts'
import { useContext } from 'react'

const Nav = ({ loggedIn, orders }) => {
  const userContext = useContext(UserContext)

  return <nav className={styles.nav}>
    <a  className={styles.logo} src="images/logo.png" href="https://clubjudo.spb.ru"></a>
     
    <div className={styles.nav__container}>
      <ul className={styles.nav__items}>
        {navigation.map(item => {
          if (!loggedIn && item.auth) { return null }
          if (item.title === 'Создать занятие' && !userContext.is_staff && !userContext.is_superuser) { return null }
          if (item.title === 'Создать новость' && !userContext.is_staff && !userContext.is_superuser) { return null }
          return <li className={cn(styles.nav__item, {
            [styles.nav__item_active]: false
          })} key={item.href}>
            <LinkComponent
              title={item.title}
              activeClassName={styles.nav__link_active}
              href={item.href}
              exact
              className={styles.nav__link}
            />
            {item.href === '/about' && orders > 0 && <span className={styles['orders-count']}>{orders}</span>}
          </li>
        })}
      </ul>
    </div>
  </nav>
}

export default Nav
