import { Container, FileInput, Input, Title, Main, Form, Button, Textarea } from '../../components'
import styles from './styles.module.css'
import api from '../../api'
import {useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { UserContext } from '../../contexts'

const AboutCreate = ({ onEdit }) => {
  const [ aboutTheme, setAboutTheme ] = useState('')
  const history = useHistory()
  const [ aboutText, setAboutText ] = useState('')
  const [ datePosted, setDatePosted] = useState('');
  const [ aboutFile, setAboutFile ] = useState(null)
  const userContext = useContext(UserContext)

  const checkIfDisabled = () => {
    return aboutText === '' ||
    aboutTheme === '' ||
    aboutFile === '' ||
    aboutFile === null
  }

  useEffect(() => {
    if (!userContext || (!userContext.is_staff && !userContext.is_superuser)) {
      history.push('/about')
    }
  }, [userContext])

  return <Main>
    <Container>
      <Helmet>
        <title>Создание занятия</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Создание Поста" />
        <meta property="og:title" content="Создание поста" />
      </Helmet>
      <Title title='Создание поста' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          const data = {
            text: aboutText,
            theme: aboutTheme,
            image: aboutFile,
            date_posted: datePosted,
          }
          api
          .createAbout(data)
          .then(res => {
            history.push(`/about/${res.id}`)
          })
          .catch(err => {
            const { non_field_errors} = err
            if (non_field_errors) {
              return alert(non_field_errors.join(', '))
            }
            const errors = Object.values(err)
            if (errors) {
              alert(errors.join(', '))
            }
          })
        }}
      >
        <div className={styles.aboutInp}>
        <Input
          label='Название новости'
          onChange={e => {
            const value = e.target.value
            setAboutTheme(value)
          }}
          
        />
        </div>
        <Textarea
          label='Описание новости'
          onChange={e => {
            const value = e.target.value
            setAboutText(value)
          }}
          className={styles.textareaField}
        />
        <FileInput
          onChange={file => {
            setAboutFile(file)
          }}
          className={styles.fileInput}
          label='Загрузить фото'
        />
        <Button
          modifier='style_dark-blue'
          disabled={checkIfDisabled()}
          className={styles.button}
        >
          Создать пост
        </Button>
      </Form>
    </Container>
  </Main>
}

export default AboutCreate
