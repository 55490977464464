import styles from './style.module.css'
import { Nav, AccountMenu, Icons} from '../index.js'
import Container from '../container'
import { Helmet } from 'react-helmet';

const Header = ({ loggedIn, onSignOut, orders }) => {
  return <header
    className={styles.header}
  >
    <Container>
    <Helmet>
        <title></title>
        <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" />
        <meta property="og:title" content="Заголовок" />
      </Helmet>
      <div className={styles.headerContent}>
          <Nav loggedIn={loggedIn} orders={orders} />
          <div className={styles.text}><a>С 1996 года работаем для Вас!</a>
           <a className={styles.centered_text} href="https://vk.com/club_judo_spb">
              <Icons.Vkicon />
            </a></div>
          <AccountMenu onSignOut={onSignOut} />
      </div>
    </Container>
  </header>
}

export default Header
