import { Container,  FileInput, Input, Title,  Main, Form, Button, Textarea } from '../../components'
import styles from './styles.module.css'
import api from '../../api'
import { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { UserContext } from '../../contexts'


const AboutEdit = ({ onItemDelete }) => {
  const [ aboutTheme, setAboutTheme ] = useState('')
  const [ aboutText, setAboutText ] = useState('')
  const [ aboutFile, setAboutFile ] = useState(null)
  const [
    aboutFileWasManuallyChanged,
    setAboutFileWasManuallyChanged
  ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const history = useHistory()
  const userContext = useContext(UserContext)
  
  const { id } = useParams()
  useEffect(() => {
    api.getAbout({
      about_id: id
    }).then(res => {
      const {
        image,
        theme,
        text
      } = res;
      setAboutText(text);
      setAboutTheme(theme);
      setAboutFile(image);
      setLoading(false);
    }).catch(err => {
      history.push('/about');
    });
  }, [id]);

  const checkIfDisabled = () => {
    return aboutText === '' ||
    aboutTheme === '' ||
    aboutFile === '' ||
    aboutFile === null
  }

  useEffect(() => {
    if (!userContext || (!userContext.is_staff && !userContext.is_superuser)) {
      history.push('/about')
    }
  }, [userContext])

  return ( <Main>
    <Container>
      <Helmet>
        <title>Редактирование новости</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Редактирование поста" />
        <meta property="og:title" content="Редактирование поста" />
      </Helmet>
      <Title title='Редактирование поста' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          const data = {
            text: aboutText,
            theme: aboutTheme,
            image: aboutFile,
            about_id: id
          }
          api
            .updateAbout(data, aboutFileWasManuallyChanged)
            .then(res => {
              history.push(`/about/${id}`)
            })
            .catch(err => {
              const { non_field_errors} = err
              if (non_field_errors) {
                return alert(non_field_errors.join(', '))
              }
              const errors = Object.values(err)
              if (errors) {
                alert(errors.join(', '))
              }
            })
        }}
      >
        <div className={styles.aboutInp}>
          <Input
            label='Название поста'
            onChange={e => setAboutTheme(e.target.value)}
            value={aboutTheme}
          />
        </div>
        <Textarea
          label='Описание поста'
          onChange={e => setAboutText(e.target.value)}
          value={aboutText}
        />
        <FileInput
          onChange={file => {
            setAboutFileWasManuallyChanged(true)
            setAboutFile(file)
          }}
          className={styles.fileInput}
          label='Загрузить фото'
          file={aboutFile}
        />
        <div className={styles.actions}>
          <Button
            modifier='style_dark-blue'
            disabled={checkIfDisabled()}
            className={styles.button}
          >
            Редактировать занятие
          </Button>
          <div
            className={styles.deleteAbout}
            onClick={_ => {
              api.deleteAbout({ about_id: id })
                .then(res => {
                  onItemDelete && onItemDelete()
                  history.push('/about')
                })
            }}
          >
            Удалить
          </div>
        </div>
      </Form>
    </Container>
  </Main>)
}

export default AboutEdit
