import { useHistory, useParams } from 'react-router-dom';
import {
  Title,
  Container,
  Main,
  Input,
  Form, 
  Button,
} from '../../components'
import cn from 'classnames'
import styles from './styles.module.css'
import { useEffect, useState} from 'react'
import api from '../../api'
import { Helmet } from 'react-helmet'

const UserEdit = ({ onItemDelete }) => {
  const [username, setusername] = useState('');
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [children, setchildren] = useState('');
  const [old, setold] = useState('');
  const [nomber, setnomber] = useState('');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    api.getUser({ 
      id: id 
    }).then((res) => {
      const { 
        first_name, 
        username, 
        last_name, 
        children, 
        old, 
        nomber
      } = res;
      setusername(username)
      setfirst_name(first_name)
      setlast_name(last_name)
      setchildren(children)
      setold(old)
      setnomber(nomber)
      setLoading(false)
    })
  }, [id]);

  const checkIfDisabled = () => {
    return first_name === '' ||
    username === '' ||
    last_name === '' || 
    children === '' || 
    old === '' ||
    nomber === '' ||
    nomber === null
  }

  return (
    <Main>
      <Container>
        <Helmet>
          <title>Профиль пользователя</title>
          <meta name="description" content="клуб Дзюдо Уняшка - Редактирование профиля" />
          <meta property="og:title" content="Редактирование профиля" />
        </Helmet>
        <Title title="Редактирование профиля" />
        <Form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            const data = {
              id: id,
              username: username,
              first_name: first_name,
              children: children,
              old : old ,  
              nomber : nomber,
              last_name: last_name
            };
            api
              .updateUser(data)
              .then((res) => {
                history.push(`/user/${id}`);
              })
              .catch((err) => {
                const { non_field_errors } = err;
                if (non_field_errors) {
                  alert(non_field_errors.join(', '));
                } else {
                  const errors = Object.values(err);
                  alert(errors.join(', '));
                }
              });
          }}
        >
          <Input
          label='Имя пользователя'
          onChange={e => {
            const value = e.target.value
            setusername(value)
          }}
          value={username}
        />
        <Input
          label='Имя родителя'
          onChange={e => {
            const value = e.target.value
            setfirst_name(value)
          }}
          value={first_name}
        />
        <Input
          label='Фамилия'
          onChange={e => {
            const value = e.target.value
            setlast_name(value)
          }}
          value={last_name}
        />
        <Input
          label='Имя ребенка'
          onChange={e => {
            const value = e.target.value
            setchildren(value)
          }}
          value={children}
        />
        <Input
          label='Возраст ребенка'
          onChange={e => {
            const value = e.target.value
            setold(value)
          }}
          value={old}
        />
        <Input
          label='Номер телефона'
          onChange={e => {
            const value = e.target.value
            setnomber(value)
          }}
          value={nomber}
        />
          <div className={styles.actions}>
          <Button
            modifier='style_dark-blue'
            disabled={checkIfDisabled()}
            className={styles.button}
          >
            Редактировать профиль
          </Button>
          </div>
        </Form>
      </Container>
    </Main>
  );
};

export default UserEdit;
