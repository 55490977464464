import './App.css';
import { Switch, Route, useHistory, Redirect, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Header, Footer, ProtectedRoute } from './components'
import api from './api'
import styles from './styles.module.css'
import cn from 'classnames'
import hamburgerImg from './images/hamburger-menu.png'



import {
  Main,
  Posts,
  SignIn,
  Favorites,
  Contacts,
  SignUp,
  LessonEdit,
  LessonCreate,
  AboutEdit,
  AboutCreate,
  SingleCardUser,
  ChangePassword,
  ImageGallery,
  SingleCard,
  SingleCardPost,
  UserEdit,
  ResetPassword,
  PasswordResetConfirm
  
} from './pages'

import { AuthContext, UserContext } from './contexts'
import Slideshow from './pages/gallary/Slideshow';

function App() {
  const [ loggedIn, setLoggedIn ] = useState(null)
  const [ user, setUser ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ orders, setOrders ] = useState(0)
  const [ menuToggled, setMenuToggled ] = useState(false)
  const location = useLocation()

  const registration = ({
    email,
    password,
    username,
    first_name,
    last_name,
    children,
    old,
    nomber
  }) => {
    api.signup({ email, 
      password, 
      username, 
      first_name, 
      last_name, 
      children, old, nomber
    })
      .then(res => {
        history.push('/signin')
      })
      .catch(err => {
        const errors = Object.values(err)
        if (errors) {
          alert(errors.join(', '))
        }
        setLoggedIn(false)
      })
  }

  const changePassword = ({
    new_password,
    current_password
  }) => {
    api.changePassword({ new_password, current_password })
      .then(res => {
        history.push('/signin')
      })
      .catch(err => {
        const errors = Object.values(err)
        if (errors) {
          alert(errors.join(', '))
        }
      })
  }

  const authorization = ({
    email, password
  }) => {
    api.signin({
      email, password
    }).then(res => {
      if (res.auth_token) {
        localStorage.setItem('token', res.auth_token)
        api.getUserData()
          .then(res => {
            setUser(res)
            setLoggedIn(true)
          })
          .catch(err => {
            setLoggedIn(false)
            history.push('/signin')
          })
      } else {
        setLoggedIn(false)
      }
    })
    .catch(err => {
      const errors = Object.values(err)
      if (errors) {
        alert(errors.join(', '))
      }
      setLoggedIn(false)
    })
  }

  const loadSingleItem = ({ id, callback }) => {
    setTimeout(_ => {
      callback()
    }, 3000)
  }

  const history = useHistory()
  const onSignOut = () => {
    api
      .signout()
      .then(res => {
        localStorage.removeItem('token')
        setLoggedIn(false)
      })
      .catch(err => {
        const errors = Object.values(err)
        if (errors) {
          alert(errors.join(', '))
        }
      })
  }

  useEffect(_ => {
    if (loggedIn) {
    }
  }, [loggedIn])


  const updateOrders = (add) => {
    if (!add && orders <= 0) { return }
    if (add) {
      setOrders(orders + 1)
    } else {
      setOrders(orders - 1)
    }
  }

  useEffect(_ => {
    const token = localStorage.getItem('token')
    if (token) {
      return api.getUserData()
        .then(res => {
          setUser(res)
          setLoggedIn(true)
        })
        .catch(err => {
          setLoggedIn(false)
          history.push('/signin')
        })
    }
    setLoggedIn(false)
  }, [])

  if (loggedIn === null) {
    return <div className={styles.loading}>Загружаемся!!</div>
  }
  
  return <AuthContext.Provider value={loggedIn}>
    <UserContext.Provider value={user}>
      <div className={cn("App", {
        [styles.appMenuToggled]: menuToggled
      })}>
        <div
          className={styles.menuButton}
          onClick={_ => setMenuToggled(!menuToggled)}
        >
          <img src={hamburgerImg} />
        </div>
        <Header orders={orders} loggedIn={loggedIn} onSignOut={onSignOut} />
        <Switch>
          <ProtectedRoute
            exact
            path='/favorites'
            component={Favorites}
            loggedIn={loggedIn}
            updateOrders={updateOrders}
          />

          <ProtectedRoute
            exact
            path='/lessons/create'
            component={LessonCreate}
            loggedIn={loggedIn}
          />
          <ProtectedRoute
            exact
            path='/about/create'
            component={AboutCreate}
            loggedIn={loggedIn}
          />

          <ProtectedRoute
            exact
            path='/lessons/:id/edit'
            component={LessonEdit}
            loggedIn={loggedIn}
            loadItem={loadSingleItem}
          />
          <ProtectedRoute
            exact
            path='/about/:id/edit'
            component={AboutEdit}
            loggedIn={loggedIn}
            loadItem={loadSingleItem}
          />
          <ProtectedRoute
            exact
            path='/user/:id/edit'
            component={UserEdit}
            loggedIn={loggedIn}
            loadItem={loadSingleItem}
          />
          <ProtectedRoute
            exact
            path='/change-password'
            component={ChangePassword}
            loggedIn={loggedIn}
            onPasswordChange={changePassword}
          />
          <Route
            exact
            path='/lessons/:id'
          >
            <SingleCard
              loggedIn={loggedIn}
              loadItem={loadSingleItem}
              updateOrders={updateOrders}
            />
          </Route>
          <Route
            exact
            path='/about/:id'
          >
            <SingleCardPost
              loggedIn={loggedIn}
              loadItem={loadSingleItem}
            />
          </Route>
          <Route
            exact
            path='/user/:id'
          >
            <SingleCardUser
              loggedIn={loggedIn}
              loadItem={loadSingleItem}
            />
          </Route>
          <Route
            exact
            path='/gallary/:id'
          >
            <Slideshow
              loggedIn={loggedIn}
              loadItem={loadSingleItem}
            />
          </Route>

          <Route exact path='/lessons'>
            <Main
            />
          </Route>
          <Route exact path='/about'>
            <Posts
            />
          </Route>
          <Route exact path='/contacts'>
            <Contacts
            />
          </Route>
          <Route exact path='/gallary'>
            <ImageGallery
            />
          </Route>
              <Route exact path='/send_reset_password/'>
                <ResetPassword
                />
              </Route>
              <Route exact path='/password/reset/confirm/:uid/:token'>
                <PasswordResetConfirm
                />
              </Route>
          
          <Route exact path='/signin'>
            <SignIn
              onSignIn={authorization}
            />
          </Route>
          <Route exact path='/signup'>
            <SignUp
              onSignUp={registration}
            />
          </Route>
          <Route exact path='/'>
            <Redirect to='/about' />
          </Route> 
        </Switch>
        <Footer />
      </div>
    </UserContext.Provider>
  </AuthContext.Provider>
}

export default App;
