import styles from './style.module.css'
import { AlbumsContainer } from '../index'


const CardGallary = ({
  caption = 'Без названия',
  id,
  image,
  albums,
  handleImageClick,
}) => {
  return <div className={styles.card} onClick={() => handleImageClick({ id, image, albums })}>
        <div className={styles.card__image} style={{ backgroundImage: `url(${image})` }}></div>
      <div className={styles.card__body}>
        <p className={styles.card__title}>{caption}</p>
      <AlbumsContainer albums={albums} />
      </div>
    </div>
}; 

export default CardGallary
