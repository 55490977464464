import styles from './style.module.css'
import { LinkComponent, Icons, Button, TagsContainer } from '../index'
import { useContext } from 'react'
import { AuthContext } from '../../contexts'

const Card = ({
  name = 'Без названия',
  id,
  image,
  is_favorited,
  tags,
  lessons_time,
  handleLike
}) => {
  const authContext = useContext(AuthContext)
  return <div className={styles.card}>
      <LinkComponent
        className={styles.card__title}
        href={`/lessons/${id}`}
        title={<div className={styles.card__image} style={{ backgroundImage: `url(${ image })` }} />}
      />
      <div className={styles.card__body}>
        <LinkComponent
          className={styles.card__title}
          href={`/lessons/${id}`}
          title={name}
        />
        <TagsContainer tags={tags} />
        <div className={styles.card__time}>
          <Icons.ClockIcon /> {lessons_time} мин.
        </div>
      </div>
          {authContext && 
          <Button
          modifier='style_none'
          clickHandler={_ => {
            handleLike({ id, toLike: Number(!is_favorited) })
          }}
        >
          {is_favorited ? 
            <>
              <Icons.StarActiveIcon />
              <span>нажми чтобы забыть</span>
            </> 
            : 
            <>
              <Icons.StarIcon />
              <span>
              нажми чтобы не потерять</span>
            </>
          }
        </Button>}
      </div>
}

export default Card