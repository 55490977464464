class Api {
  constructor (url, headers) {
    this._url = url
    this._headers = headers
  }

  checkResponse (res) {
    return new Promise((resolve, reject) => {
      if (res.status === 204) {
        return resolve(res)
      }
      const func = res.status < 400 ? resolve : reject
      res.json().then(data => func(data))
    })
  }

  signin ({ email, password }) {
    return fetch(
      '/api/auth/token/login/',
      {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          email, password
        })
      }
    ).then(this.checkResponse)
  }

  signout () {
    const token = localStorage.getItem('token')
    return fetch(
      '/api/auth/token/logout/',
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  signup({
    email,
    password,
    username,
    first_name,
    last_name,
    children,
    old,
    nomber,
  }) {
    return fetch(
      '/api/users/',
      {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          email,
          password,
          username,
          first_name,
          last_name,
          children,
          old,
          nomber,
        })
      }
    ).then(response => {
      return this.checkResponse(response);
    });
  }

  getUserData () {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/users/me/`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  
  changePassword ({ current_password, new_password }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/users/set_password/`,
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({ current_password, new_password })
      }
    ).then(this.checkResponse)
  }
  
  passwordChange ({ email, password }) {
    return fetch(
      '/api/auth/token/login/',
      {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          email, password
        })
      }
    ).then(this.checkResponse)
  }

  getLessons ({
   page = 1,
   limit = 6,
   is_favorited = 0,
   author,
   tags
  } = {}) {
      const token = localStorage.getItem('token')    
      const authorization = token ? { 'authorization': `Token ${token}` } : {}
      const tagsString = tags ? tags.filter(tag => tag.value).map(tag => `&tags=${tag.slug}`).join('') : ''    
      return fetch(
        `/api/lessons/?page=${page}&limit=${limit}${author ? `&author=${author}` : ''}${is_favorited ? `&is_favorited=${is_favorited}` : ''}${tagsString}`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...authorization
          }
        }
      ).then(this.checkResponse)
  }

  getLesson ({
    lesson_id
  }) {
    const token = localStorage.getItem('token')
    const authorization = token ? { 'authorization': `Token ${token}` } : {}
    return fetch(
      `/api/lessons/${lesson_id}/`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          ...authorization
        }
      }
    ).then(this.checkResponse)
  }

  createLesson ({
    name = '',
    image,
    tags = [],
    lessons_time = 0,
    text = '',
  }) {
    const token = localStorage.getItem('token')
    return fetch(
      '/api/lessons/',
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          name,
          image,
          tags,
          lessons_time,
          text
        })
      }
    ).then(this.checkResponse)
  }

  updateLesson ({
    name,
    lesson_id,
    image,
    tags,
    lessons_time,
    text
  }, wasImageUpdated) { // image was changed
    const token = localStorage.getItem('token')
    return fetch(
      `/api/lessons/${lesson_id}/`,
      {
        method: 'PATCH',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          name,
          id: lesson_id,
          image: wasImageUpdated ? image : undefined,
          tags,
          lessons_time: Number(lessons_time),
          text,
        })
      }
    ).then(this.checkResponse)
  }

  addToFavorites ({ id }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/lessons/${id}/favorite/`,
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  removeFromFavorites ({ id }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/lessons/${id}/favorite/`,
      {
        method: 'DELETE',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  getUser ({ id }) {
    const token = localStorage.getItem('token')
  
    return fetch(
      `/api/users/${id}/`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  updateUser({
    username,
    id,
    profilePhoto,
    first_name,
    last_name,
    children,
    old,
    nomber
  }, wasImageUpdated) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/users/${id}/`,
      {
        method: 'PATCH',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          username,
          id: id,
          profilePhoto: wasImageUpdated ? profilePhoto : undefined,
          first_name,
          last_name,
          children,
          old,
          nomber
        })
      }
    ).then(this.checkResponse)
  }

  getUsers ({
    page = 1,
    limit = 6
  }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/users/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }
// about

  getAbouts ({
    page = 1,
    limit = 6,
  } = {}) {
      const token = localStorage.getItem('token')
      const authorization = token ? { 'authorization': `Token ${token}` } : {}
      return fetch(
        `/api/about/?page=${page}&limit=${limit}`,
        {
          method: 'GET',
          headers: {
            ...this._headers,
            ...authorization
          }
        }
      ).then(this.checkResponse)
  }

  getAbout ({
    about_id
  }) {
    const token = localStorage.getItem('token')
    const authorization = token ? { 'authorization': `Token ${token}` } : {}
    return fetch(
      `/api/about/${about_id}/`,
      {
        method: 'GET',
        headers: {
          ...this._headers,
          ...authorization
        }
      }
    ).then(this.checkResponse)
  }


  createAbout ({
    theme = '',
    image,
    text = '',
    date_posted,
  }) {
    const token = localStorage.getItem('token')
    return fetch(
      '/api/about/',
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          theme,
          image,
          text,
          date_posted,
        })
      }
    ).then(this.checkResponse)
  }

  updateAbout ({
    theme,
    about_id,
    text,
    date_posted,
    image,
  }, wasImageUpdated) { 
    const token = localStorage.getItem('token')
    return fetch(
      `/api/about/${about_id}/`,
      {
        method: 'PATCH',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          theme,
          id: about_id,
          image: wasImageUpdated ? image : undefined,
          text,
          date_posted
        })
      }
    ).then(this.checkResponse)
  }

  // tags
  getTags () {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/tags/`,
      {
        method: 'GET',
        headers: {
          ...this._headers
        }
      }
    ).then(this.checkResponse)
  }

  deleteLesson ({ lesson_id }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/lessons/${lesson_id}/`,
      {
        method: 'DELETE',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  }

  getGallarys ({
    page = 1,
    limit = 6,
    albums,
   } = {}) {
       const albumsString = albums ? albums.filter(album => album.value).map(album => `&albums=${album.slug}`).join('') : ''    
       return fetch(
         `api/gallary/?page=${page}&limit=${limit}${albumsString}`,
         {
           method: 'GET',
           headers: {
             ...this._headers,
           },
         }
       ).then(this.checkResponse)
  } 

  getGallary ({
    gallary_id
  }) {
    return fetch(
      `/api/gallary/${gallary_id}/`,
      { method: 'GET',
        headers: {
          ...this._headers
        }
      }
    ).then(this.checkResponse)

  }
  createGallary ({
    caption = '',
    image,
    albums = [],
    description = '',
  }) {
    const token = localStorage.getItem('token')
    return fetch(
      '/api/gallary/',
      {
        method: 'POST',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          caption,
          image,
          albums,
          description
        })
      }
    ).then(this.checkResponse)
  }

  updateLesson ({
    caption,
    gallary_id,
    image,
    albums,
    description
  }, wasImageUpdated) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/gallary/${gallary_id}/`,
      {
        method: 'PATCH',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        },
        body: JSON.stringify({
          caption,
          id: gallary_id,
          image: wasImageUpdated ? image : undefined,
          albums,
          description,
        })
      }
    ).then(this.checkResponse)
  }
  
  getAlbums () {
    return fetch(
      `/api/albums/`,
      {
        method: 'GET',
        headers: {
          ...this._headers
        }
      }
    ).then(this.checkResponse)
  }

  deleteAbout ({ about_id }) {
    const token = localStorage.getItem('token')
    return fetch(
      `/api/about/${about_id}/`,
      {
        method: 'DELETE',
        headers: {
          ...this._headers,
          'authorization': `Token ${token}`
        }
      }
    ).then(this.checkResponse)
  } 
}

export default new Api(process.env.API_URL || 'http://localhost', { 'content-type': 'application/json' })
