import styles from './style.module.css'
import { LinkComponent, } from '../index'
import { useContext } from 'react'
import { AuthContext } from '../../contexts'

const CardPost = ({
  theme = 'Без названия',
  id,
  image,

}) => {
  const authContext = useContext(AuthContext)
  return <div className={styles.card}>
      <LinkComponent
        className={styles.card__title}
        href={`/about/${id}`}
        title={<div className={styles.card__image} style={{ backgroundImage: `url(${ image })` }} />}
      />
      <div className={styles.card__body}>
        <LinkComponent
          className={styles.card__title}
          href={`/about/${id}`}
          title={theme}
        />
        </div>
      </div>
}

export default CardPost