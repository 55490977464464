import * as React from "react";

function Vkicon(props) {
  return (
    <svg 
        enable-background="new 0 0 100 100" 
        height="100px" 
        id="Layer_1" 
        version="1.1" 
        viewBox="0 0 100 100" 
        width="100px" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <path 
        d="M50,3.84C24.506,3.84,3.84,24.506,3.84,50S24.506,96.16,50,96.16S96.16,75.494,96.16,50S75.494,3.84,50,3.84  z M74.808,67.517c-1.97,1.232-9.975,1.599-11.913,0.191c-1.063-0.772-2.035-1.738-2.958-2.658c-0.646-0.643-1.379-0.955-1.934-1.653  c-0.453-0.571-0.764-1.245-1.241-1.809c-0.805-0.946-2.044-1.751-3.024-0.595c-1.476,1.739,0.232,5.154-1.736,6.453  c-0.66,0.436-1.326,0.562-2.151,0.5l-1.827,0.083c-1.073,0.021-2.773,0.031-3.994-0.189c-1.362-0.246-2.487-0.99-3.719-1.536  c-2.338-1.037-4.565-2.45-6.26-4.407c-4.613-5.33-10.809-12.66-13.216-19.361c-0.495-1.377-1.803-4.104-0.563-5.285  c1.686-1.225,9.961-1.57,11.252,0.324c0.524,0.77,0.855,1.896,1.226,2.769c0.462,1.088,0.712,2.113,1.434,3.076  c0.639,0.854,1.11,1.713,1.604,2.649c0.555,1.051,1.079,2.059,1.754,3.026c0.458,0.658,1.669,1.967,2.434,2.064  c1.868,0.239,1.752-4.3,1.613-5.408c-0.133-1.068-0.167-2.201-0.132-3.284c0.03-0.924,0.113-2.226-0.434-2.98  c-0.892-1.23-2.879-0.31-3.034-1.963c0.328-0.469,0.259-0.885,2.45-1.607c1.726-0.567,2.841-0.549,3.979-0.458  c2.32,0.186,4.78-0.442,7.02,0.31c2.14,0.721,1.809,3.762,1.737,5.584c-0.097,2.487,0.007,4.915,0,7.44  c-0.003,1.149-0.049,2.268,1.353,2.174c1.314-0.087,1.448-1.193,2.075-2.105c0.872-1.272,1.673-2.562,2.561-3.829  c1.197-1.713,1.559-3.638,2.686-5.393c0.403-0.627,0.75-1.963,1.371-2.459c0.47-0.377,1.363-0.18,1.932-0.18h1.353  c1.035,0,2.091-0.008,3.155,0.029c1.532,0.053,3.248-0.299,4.767-0.084c6.555,0.925-8.239,14.938-7.469,17.44  c0.532,1.728,3.907,3.659,5.125,5.074C73.701,61.345,78.679,65.092,74.808,67.517z" 
        fill="#4C75A3"/>
    </svg>
  );
}

export default Vkicon;