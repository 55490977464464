import React from 'react';
import styles from './style.module.css';
import {Container, Main, AlbumsContainer } from '../../components';
import { Helmet } from 'react-helmet';

const Slideshow = ({ images, selectedImage, setSelectedImage, closeSlideshow , albums}) => { 
  const currentIndex = images.findIndex(image => image === selectedImage); 
  const [startX, setStartX] = React.useState(null);
  const [currentX, setCurrentX] = React.useState(null);
 
  const handleNext = () => { 
    const nextIndex = (currentIndex + 1) % images.length; 
    setSelectedImage(images[nextIndex]); 
  }; 
 
  const handlePrev = () => { 
    const prevIndex = (currentIndex - 1 + images.length) % images.length; 
    setSelectedImage(images[prevIndex]); 
  }; 
 
  const handleClose = () => { 
    closeSlideshow(); 
  }; 
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };
  
  const handleTouchMove = (e) => {
    setCurrentX(e.touches[0].clientX);
  };
  
  const handleTouchEnd = () => {
    if (startX && currentX) {
      if (currentX - startX > 50) {
        handlePrev();
      } else if (currentX - startX < -50) {
        handleNext();
      }
    }
    setStartX(null);
    setCurrentX(null);
  };
  

  return ( 
    <Main>
    <Container>
    <Helmet> 
          <title>Фотогалерея</title> 
          <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" /> 
          <meta property="og:title" content="Слайд шоу" /> 
        </Helmet> 
      <div className={styles.slideshow} 
        onTouchStart={handleTouchStart} 
        onTouchMove={handleTouchMove} 
        onTouchEnd={handleTouchEnd}>
        <img src={selectedImage.image} />
        <div className={styles.buttons}>
          <AlbumsContainer albums={albums} />
          <button onClick={handlePrev}>Прошлое фото</button> 
          <button onClick={handleNext}>Следующее фото</button> 
          <button onClick={handleClose}>закрыть</button>
          <div className={styles.close} onClick={handleClose}>×</div>
          </div>
      </div>
    </Container>
  </Main> 
  ); 
  
}; 
 
export default Slideshow;

