import cn from 'classnames'
import styles from './styles.module.css'
import { useContext } from 'react'
import { LinkComponent } from '../index.js'
import { AuthContext } from '../../contexts'

const AccountMenu = ({ onSignOut }) => {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    return <div className={styles.menu1}>
      <LinkComponent
        className={styles.menuLink}
        href='/signin'
        title='Войти'
      />
      <LinkComponent
        href='/signup'
        title='Создать аккаунт'
        className={styles.menuButtons}
      />
    </div>
  }
  return <div className={styles.menu2}>
    <LinkComponent
      className={styles.menuLink1}
      href='/user/me'
      title='Мой кабинет'
    />
    <a
      className={styles.menuLink}
      onClick={onSignOut}
    >
      Выход
    </a>
  </div>
}

export default AccountMenu;