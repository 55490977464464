import styles from './styles.module.css'
import cn from 'classnames'
import { Album } from '../index'

const AlbumsContainer = ({ albums }) => {
  if (!albums) { return null }
  return <div className={styles['Albums-container']}>
    {albums.map(album => {
      return <Album
        key={album.id}
        color={album.color}
        name={album.name}
      />
    })}
  </div>
}

export default AlbumsContainer
