import React from 'react';
import {Container, Main, Button,  Form } from '../../components';
import styles from './style.module.css'
import { Helmet } from 'react-helmet'


const Modal = ({ isOpen, values, closeModal, onConfirm }) => { 
  
  const handleClose = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    
      <Main>
        <Container>
          <Helmet>
            <title>Регистрация</title>
            <meta name="description" content="клуб Дзюдо Уняшка - Политика конфиденциальности" />
            <meta property="og:title" content="Политика конфиденциальности" />
          </Helmet>
          <div className={styles.modal}>
            <Form className={styles.Form } onSubmit={e => {
                e.preventDefault()
                onConfirm(values)
              }}>
              <div className={styles.modals}>
                <h2>Политка конфиденциальности</h2>
                <div>
                    <p>
                       <a href="https://drive.google.com/file/d/18jjOwozem0Qp5lSNLcCNHXZ9W10uKHJ_/view">прочитать политику конфиденциальности </a>
                        согласны? нажмите "Принимаю"
                    </p>
                  </div>
                <Button
                  onClick={() => {
                    onConfirm(values);
                    closeModal();
                  }}
                  modifier='style_dark-blue'
                  type='submit'
                  className={styles.Button}
                >
                  Принимаю
                </Button>
                <Button 
                  onClick={handleClose}
                  className={styles.Button}
                >
                  Выход
                </Button>
              </div>                
            </Form>
          </div>        
        </Container>
      </Main>    
    );
};

export default Modal;