import { Container, FileInput, Input, Title, CheckboxGroup, Main, Form, Button, Textarea } from '../../components'
import styles from './styles.module.css'
import api from '../../api'
import { useEffect, useState, useContext } from 'react'
import { useTags } from '../../utils'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { UserContext } from '../../contexts'

const LessonEdit = ({ onItemDelete }) => {
  const { value, handleChange, setValue } = useTags()
  const [ lessonName, setLessonName ] = useState('')
  const [ lessonText, setLessonText ] = useState('')
  const [ lessonTime, setLessonTime ] = useState('')
  const [ lessonFile, setLessonFile ] = useState(null)
  const [
    lessonFileWasManuallyChanged,
    setLessonFileWasManuallyChanged
  ] = useState(false)

  const [ loading, setLoading ] = useState(true)
  const history = useHistory()
  const userContext = useContext(UserContext)

  
  useEffect(() => {
    api.getTags()
      .then(tags => {
        setValue(tags.map(tag => ({ ...tag, value: true })))
      })
  }, [])

  const { id } = useParams()
  useEffect(() => {
    if (value.length === 0 || !loading) { return }
    api.getLesson ({
      lesson_id: id
    }).then(res => {
      const {
        image,
        tags,
        lessons_time,
        name,
        text
      } = res
      setLessonText(text)
      setLessonName(name)
      setLessonTime(lessons_time)
      setLessonFile(image)

      const tagsValueUpdated = value.map(item => {
        item.value = Boolean(tags.find(tag => tag.id === item.id))
        return item
      })
      setValue(tagsValueUpdated)
      setLoading(false)
    })
    .catch(err => {
      history.push('/lessons')
    })
  }, [value])

  
  const checkIfDisabled = () => {
    return lessonText === '' ||
    lessonName === '' ||
    value.filter(item => item.value).length === 0 ||
    lessonTime === '' ||
    lessonFile === '' ||
    lessonFile === null
  }

  useEffect(() => {
    if (!userContext || (!userContext.is_staff && !userContext.is_superuser)) {
      history.push('/lessons')
    }
  }, [userContext])

  return <Main>
    <Container>
      <Helmet>
        <title>Редактирование занятия</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Редактирование занятия" />
        <meta property="og:title" content="Редактирование занятия" />
      </Helmet>
      <Title title='Редактирование занятия' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          const data = {
            text: lessonText,
            name: lessonName,
            tags: value.filter(item => item.value).map(item => item.id),
            lessons_time: lessonTime,
            image: lessonFile,
            lesson_id: id
          }
          api
            .updateLesson(data, lessonFileWasManuallyChanged)
            .then(res => {
              history.push(`/lessons/${id}`)
            })
            .catch(err => {
              const { non_field_errors, lessons_time } = err
              if (non_field_errors) {
                return alert(non_field_errors.join(', '))
              }
              
              if (lessons_time) {
                return alert(`Время занятия: ${lessons_time[0]}`)
              }
              const errors = Object.values(err)
              if (errors) {
                alert(errors.join(', '))
              }
            })
        }}
      >
        <div className={styles.LessonsInp}>
        <Input
          label='Название занятия'
          onChange={e => {
            const value = e.target.value
            setLessonName(value)
          }}
          value={lessonName}
        />
        </div>
        <CheckboxGroup
          label='Теги'
          values={value}
          className={styles.checkboxGroup}
          labelClassName={styles.checkboxGroupLabel}
          tagsClassName={styles.checkboxGroupTags}
          checkboxClassName={styles.checkboxGroupItem}
          handleChange={handleChange}
        />
        <div className={styles.LessonsTime}>
          <Input
            value={lessonTime}
            label='Время занятия'
            className={styles.LessonsTimeInput}
            labelClassName={styles.LessonsTimeLabel}
            inputClassName={styles.LessonsTimeValue}
            onChange={e => {
              const value = e.target.value
              setLessonTime(value)
            }}
          />
          <div className={styles.LessonsTimeUnit}>мин.</div>
        </div>
        <Textarea
          label='Описание занятия'
          onChange={e => {
            const value = e.target.value
            setLessonText(value)
          }}
          value={lessonText}
        />
        <FileInput
          onChange={file => {
            setLessonFileWasManuallyChanged(true)
            setLessonFile(file)
          }}
          className={styles.fileInput}
          label='Загрузить фото'
          file={lessonFile}
        />
        <div className={styles.actions}>
          <Button
            modifier='style_dark-blue'
            disabled={checkIfDisabled()}
            className={styles.button}
          >
            Редактировать занятие
          </Button>
          <div
            className={styles.deleteLesson}
            onClick={_ => {
              api.deleteLesson({ lesson_id: id })
                .then(res => {
                  onItemDelete && onItemDelete()
                  history.push('/lessons')
                })
            }}
          >
            Удалить
          </div>
        </div>
      </Form>
    </Container>
  </Main>
}

export default LessonEdit
