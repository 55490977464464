import { Card, Title, Pagination, CardList, Container, Main, CheckboxGroup  } from '../../components'
import styles from './styles.module.css'
import { useLessons } from '../../utils/index.js'
import { useEffect } from 'react'
import api from '../../api'
import { Helmet } from 'react-helmet';

const HomePage = ({ updateOrders }) => {
  const {
    lessons,
    setLessons,
    lessonsCount,
    setLessonsCount,
    lessonsPage,
    setLessonsPage,
    tagsValue,
    setTagsValue,
    handleTagsChange,
    handleLike,
  } = useLessons()


  const getLessons = ({ page = 1, tags }) => {
    api
      .getLessons({ page, tags })
      .then(res => {
        const { results, count } = res
        setLessons(results)
        setLessonsCount(count)
      })
  }

  useEffect(_ => {
    getLessons({ page: lessonsPage, tags: tagsValue })
  }, [lessonsPage, tagsValue])

  useEffect(_ => {
    api.getTags()
      .then(tags => {
        setTagsValue(tags.map(tag => ({ ...tag, value: true })))
      })
  }, [])

  return <Main>
    <Container>
      <Helmet>
        <title>Занятия</title>
        <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" />
        <meta property="og:title" content="Занятия" />
      </Helmet>
      <div className={styles.title}>
        <Title title='Занятия' />
        <CheckboxGroup
          values={tagsValue}
          handleChange={value => {
            setLessonsPage(1)
            handleTagsChange(value)
          }}
        />
      </div>
      <CardList>
        {lessons.map(card => <Card
          {...card}
          key={card.id}
          updateOrders={updateOrders}
          handleLike={handleLike}
        />)}
      </CardList>
      <Pagination
        count={lessonsCount}
        limit={6}
        page={lessonsPage}
        onPageChange={page => setLessonsPage(page)}
      />
    </Container>
  </Main>
}

export default HomePage

