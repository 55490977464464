import styles from './styles.module.css'

const Description2 = ({ description2 }) => {
  if (!description2) { return null }
  return <div className={styles.description}>
    <h3 className={styles['description__title']}>Имя ребенка:</h3>
    <div className={styles['description__content']} dangerouslySetInnerHTML={{ __html: description2 }} />
  </div>
}

export default Description2

