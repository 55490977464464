import { useState } from "react";
import { useAlbums } from './index.js'

export default function useGallarys () {
  const [ gallarys, setGallarys ] = useState([])
  const [ gallarysCount, setGallarysCount ] = useState(0)
  const [ gallarysPage, setGallarysPage ] = useState(1)
  const { value: albumsValue, handleChange: handleAlbumsChange, setValue: setAlbumsValue } = useAlbums()

  return {
    gallarys,
    setGallarys,
    gallarysCount,
    setGallarysCount,
    gallarysPage,
    setGallarysPage,
    albumsValue,
    handleAlbumsChange,
    setAlbumsValue
  }
}
