import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

const Input = ({
  onChange,
  label,
  type = 'text',
  inputClassName,
  labelClassName,
  className,
  name,
  required,
  value = ''
}) => {
const [inputValue, setInputValue] = useState(value);
const [isFilled, setIsFilled] = useState(false);
const [isFocused, setIsFocused] = useState(false);

const handleValueChange = (e) => {
  let value = e.target.value;
  const { name } = e.target;

  if (name === 'nomber') {
    if (!/^\d*$/.test(value)) {
      value = value.replace(/[^\d()+\-]/g, ''); 
    }

    if (value.length > 14) {
      value = value.slice(0, 14);
    }

    if (value.startsWith('8')) {
        value = '+7' + value.slice(1);
    } else if (!value.startsWith('+7')) {
        value = '+7' + value;
    }

    if (value.startsWith('+7') && value.length > 2) {
      value = value.replace(/^(\+7)(\d{3})/, '$1 ($2)');
    }
  }

  setInputValue(value);
  setIsFilled(value !== '');
  onChange(e);
};

const handleFocus = () => {
  setIsFocused(true);
};

const handleBlur = () => {
  setIsFocused(inputValue !== '');
};

useEffect(_ => {
  if (value !== inputValue) {
    setInputValue(value)
  }
}, [value])

return (
  <div className={cn(styles.input, className)}>
    <label className={styles.inputLabel}>
      <div className={cn(styles.inputLabelText, labelClassName, ((isFocused || isFilled) || inputValue) && styles.inputLabelTextFilled)}>
        {label}
      </div>
      <input
        type={type}
        required={required}
        name={name}
        className={cn(styles.inputField, inputClassName)}
        onChange={handleValueChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={inputValue}
      />
    </label>
  </div>
);
};

export default Input;
