import { useState } from "react";
import { useTags } from './index.js'
import api from '../api/index.js'

export default function useLessons () {
  const [ lessons, setLessons ] = useState([])
  const [ lessonsCount, setLessonsCount ] = useState(0)
  const [ lessonsPage, setLessonsPage ] = useState(1)
  const { value: tagsValue, handleChange: handleTagsChange, setValue: setTagsValue } = useTags()

  const handleLike = ({ id, toLike = true }) => {
    const method = toLike ? api.addToFavorites.bind(api) : api.removeFromFavorites.bind(api)
    method({ id }).then(res => {
      const lessonsUpdated = lessons.map(lesson => {
        if (lesson.id === id) {
          lesson.is_favorited = toLike
        }
        return lesson
      })
      setLessons(lessonsUpdated)
    })
    .catch(err => {
      const { errors } = err
      if (errors) {
        alert(errors)
      }
    })
  }

 
  return {
    lessons,
    setLessons,
    lessonsCount,
    setLessonsCount,
    lessonsPage,
    setLessonsPage,
    tagsValue,
    handleLike,
    handleTagsChange,
    setTagsValue
  }
}
