import { Container, Input, Icons, Title, Main, Form, Button } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { Redirect } from 'react-router-dom'
import { useContext, useState, } from 'react'
import { AuthContext } from '../../contexts'
import { Helmet } from 'react-helmet'
import Modal from './modal';

const SignUp = ({ onSignUp }) => {
  const { values, handleChange, errors, isValid, resetForm} = useFormWithValidation();
  const authContext = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmSignUp = (values) => {
    setIsModalOpen(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Main>
      {authContext && <Redirect to='/about' />}
      <Container>
        <Helmet>
          <title>Регистрация</title>
          <meta name="description" content="клуб Дзюдо Уняшка - Регистрация" />
          <meta property="og:title" content="Регистрация" />
        </Helmet>
        <Title title='Регистрация' />
        <Form className={styles.form} onSubmit={(e) => {
          e.preventDefault();
          if (values.password !== values.confirmPassword) {
            alert("Пароли не совпадают. Пожалуйста, повторите ввод.");
            return;
          }
          if (!values.password.match(/[a-zA-Z]+/)) {
            alert('Пароль должен содержать хотя бы одну букву');
            return;
          }
          handleConfirmSignUp(values);
        }}>
          <Input
            label='Имя'
            name='first_name'
            required
            onChange={handleChange}
          />
          <Input
            label='Фамилия'
            name='last_name'
            required
            onChange={handleChange}
          />
          <Input
            label='Имя пользователя'
            name='username'
            required
            onChange={handleChange}
          />
          <Input
            label='Имя ребенка'
            name='children'
            required
            onChange={handleChange}
          />
          <Input
            label='Возраст ребенка'
            name='old'
            required
            onChange={handleChange}
          />
          <Input
            label='Номер для связи'
            name='nomber'
            required
            onChange={handleChange}
            />
          <Input
            label='Адрес электронной почты'
            name='email'
            onChange={handleChange}
            required
          />
           <Input
            required
            label='Пароль'
            type={showPassword ? 'text' : 'password'}
            name='password'
            onChange={handleChange}
          />
          <div className={styles.inputContainer}>
            <Input
              label='Повторите пароль'
              type={showPassword ? 'text' : 'password'}
              name='confirmPassword'
              required
              onChange={handleChange}
            />
            <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            Показать пароли
            {showPassword ? <Icons.Eyeicon /> : <Icons.EyeLineicon />}
          </button>
          </div>
          <Button
            onClick={() => {
              openModal();
            }}
            modifier='style_dark-blue'
            className={styles.button}
            disabled={!isValid}
          >
            Создать аккаунт
          </Button>
        </Form>
        {isModalOpen && <Modal
          isOpen={isModalOpen}
          values={values}
          closeModal={() => setIsModalOpen(false)}
          onConfirm={() => {
            onSignUp(values);
            closeModal();
          }}
        />}
      </Container>
    </Main>
  );
};

export default SignUp;