import { Title, Container, Main, Icons, } from '../../components'
import styles from './styles.module.css'
import { Helmet } from 'react-helmet';

const Contacts = () => {  

  return <Main>
    <Container>
      <Helmet>
        <title></title>
        <meta name="description" content="клуб Дзюдо Уняшка - клуб раннего развития" />
        <meta property="og:title" content="Связь с нами" />
      </Helmet>
      <div className={styles.title}>
        <Title title='Связь с нами' />
      </div>
      <div className={styles.frame6}>
        <iframe className={styles.frame7} src="https://yandex.ru/maps-reviews-widget/1177162240?comments">
        </iframe>
        <a href="https://yandex.ru/maps/org/unyashka/1177162240/" target="_blank" className={styles.frame8} >Уняшка на карте Санкт‑Петербурга — Яндекс Карты</a>
      
      <div className={styles.frame10}>
        <div className={styles.text2}>
          <p><span className={styles.green}>Клуб Дзюдо и детского развития</span></p>
          <p><span className={styles.red}>Номера для связи: </span><br/> +7 (921) 789-11-65 <span className={styles.mix}>Ольга Николаевна </span>- Главный менеджер<br/> +7 (921) 903-02-16 <span className={styles.mix}>Евгений Витальевич </span> - по вопросам "Дзюдо"<br/>
          <span className={styles.red}>Адрес: </span>город Санкт‑Петербург, м.Автово ул.Маринеско д.4 <br/>
          <span className={styles.red}>Почта для связи: </span> unyashka-dzudo@yandex.ru <br/>
          <span className={styles.red}>Социальные сети: </span>
            <a href="https://vk.com/club_judo_spb">
              <Icons.Vkicon />
            </a> <br/>
          <span className={styles.mix}>С 1996 года работаем для Вас!</span></p>
        </div>
        <p className={styles.text}>При обнаружении багов на сайте обращайтесь<span className={styles.red}> "mikuo.jovi@gmail.com"</span></p>
      </div>
      </div>
      
      <div className={styles.frame1}>
        <a href="https://yandex.ru/maps/org/unyashka/1177162240/?utm_medium=mapframe&utm_source=maps" className={styles.frame2}>Уняшка</a>
        <a href="https://yandex.ru/maps/2/saint-petersburg/category/children_developmental_center/184107204/?utm_medium=mapframe&utm_source=maps" className={styles.frame3}>Центр развития ребёнка в Санкт‑Петербурге</a>
        <a href="https://yandex.ru/maps/2/saint-petersburg/category/sports_club/184107297/?utm_medium=mapframe&utm_source=maps" className={styles.frame4}>Спортивный клуб, секция в Санкт‑Петербурге</a>
        <iframe src="https://yandex.ru/map-widget/v1/org/unyashka/1177162240/?ll=30.265888%2C59.869566&z=19.51" width="1100" height="440" frameborder="1" allowfullscreen="true" className={styles.frame5} >
        </iframe>
      </div>

    </Container>
  </Main>
}

export default Contacts
