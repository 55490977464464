import { 
  Container, 
  Main, 
  LinkComponent, 
  FileInput,
  Form, 
  Button
 } from '../../components'
import {useState, useEffect, useContext } from 'react'
import { UserContext } from '../../contexts'
import styles from './styles.module.css'
import Description from './description'
import Description1 from './description1'
import Description2 from './description2'
import Description3 from './description3'
import Description4 from './description4'
import { useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import prof from './image/prof.png'

import { useUser } from '../../utils/index.js'
import api from '../../api'

const SingleCardUser = ({ loadItem, updateOrders }) => {
  const [ loading, setLoading ] = useState(true)
  const {user, setUser} = useUser()
  const [profilePhoto, setprofilePhoto] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [
    userFileWasManuallyChanged, 
    setUserFileWasManuallyChanged
  ] = useState(false);
  const userContext = useContext(UserContext)
 
  const { id } = useParams()
  const history = useHistory()

  useEffect(_ => {
    api.getUser ({
        id: id
      })
      .then(res => {
        const {
        profilePhoto
      } = res;
        setUser(res)
        setprofilePhoto(profilePhoto)
        setLoading(false)
      })
      .catch(err => {
        history.push('/about')
      })
      
  }, [])

  const { url } = useRouteMatch()
  const {
    first_name,
    username,
    last_name,
    children,
    old,
    nomber
  } = user
  

  const checkIfDisabled = () => profilePhoto === '' || profilePhoto === null;

  return <Main>
  <Container>
    <Helmet>
      <title>{username}</title>
      <meta name="description" content={`клуб Дзюдо Уняшка - ${username}`} />
      <meta property="og:title" content={username} />
    </Helmet>
    <Form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            const data = {
              profilePhoto: profilePhoto,
              id: id,
            };
            api
              .updateUser(data, userFileWasManuallyChanged)
              .then((res) => {
                setShowSuccessMessage(true);
                setTimeout(() => {
                  setShowSuccessMessage(false);
                }, 3000);
                history.push(`/user/${id}`);
              })
              .catch((err) => {
                const { non_field_errors } = err;
                if (non_field_errors) {
                  alert(non_field_errors.join(', '));
                } else {
                  const errors = Object.values(err);
                  alert(errors.join(', '));
                }
              });
          }}
        >
      <div className={styles['single-card']}>
      <img src={profilePhoto || prof } alt={username} className={styles["single-card__image"]}  />
        <div className={styles["single-card__info"]}>
          <div className={styles["single-card__header-info"]}>
              <h1 className={styles["single-card__title"]}>{username}</h1>
          </div>
            <div className={styles["single-card__edit"]}>
              {(userContext.id === user.id) && (
                <>                 
                  <LinkComponent
                    href={`${url}/edit`}
                    title='Редактировать профиль'
                    className={styles['card__edit']}
                  />
                  <LinkComponent
                    className={styles.menuLink}
                    href='/change-password'
                    title='Изменить пароль'
                  />                
                </> 
              )}
            </div>
          <div className={styles.description}>
          <Description description={first_name} />
          <Description1 description1= {last_name}/>
          <Description2 description2={children} />
          <Description3 description3={old} />
          <Description4 description4={nomber} />
          {(userContext.id === user.id) && (
            <> 
              <FileInput 
                onChange={(file) => { 
                  setUserFileWasManuallyChanged(true); 
                  setprofilePhoto(file); 
                }} 
                className={styles.fileInput} 
                label="Загрузить новое фото"  
                file={profilePhoto} 
              /> 
              <Button 
                modifier='style_dark-blue' 
                disabled={checkIfDisabled()} 
                className={styles.buttons} 
              > 
                Сохранить фото 
              </Button> 
            </> 
          )}
          </div>
          {showSuccessMessage && (
            <div className={styles.successMessage}>
              Фото профиля успешно обновлено
            </div>
          )}
        </div>
      </div>
    </Form>
  </Container>
</Main>
}

export default SingleCardUser
