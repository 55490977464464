import { Container, Input, Icons, Title, Main, Form, Button } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { Helmet } from 'react-helmet';
import { useState} from 'react'

const ChangePassword = ({ onPasswordChange }) => {
  const { values, handleChange, isValid } = useFormWithValidation()
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return <Main>
    <Container>
      <Helmet>
        <title>Изменить пароль</title>
        <meta name="description" content="клуб Дзюдо Уняшка - Изменить пароль" />
        <meta property="og:title" content="Изменить пароль" />
      </Helmet>
      <Title title='Изменить пароль' />
      <Form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault()
          onPasswordChange(values)
        }}
      >
        <Input
          required
          label='Старый пароль'
          type={showPassword ? 'text' : 'password'}
          name='current_password'
          onChange={handleChange}
        />
        <div className={styles.inputContainer}>
        <Input
          required
          label='Новый пароль'
          type={showPassword ? 'text' : 'password'}
          name='new_password'
          onChange={handleChange}
        />
        <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Icons.Eyeicon /> : <Icons.EyeLineicon />}
          </button>
        </div>
        <div className={styles.inputContainer}>
        <Input
          required
          label='Подтверждение нового пароля'
          type={showPassword ? 'text' : 'password'}
          name='repeat_password'
          onChange={handleChange}
        />
        <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Icons.Eyeicon /> : <Icons.EyeLineicon />}
          </button>
        </div>
        <Button
          modifier='style_dark-blue'
          type='submit'
          disabled={!isValid || values.new_password !== values.repeat_password}
        >
          Изменить пароль
        </Button>
      </Form>
    </Container>
  </Main>
}

export default ChangePassword
