export default [
  {    
    title: 'О нас',
    href: '/about',
    auth: false
  },{ 
    title: 'Создать новость', 
    href: '/about/create', 

  },{
    title: 'Занятия',
    href: '/lessons',
    auth: false
  },{ 
    title: 'Создать занятие', 
    href: '/lessons/create', 

  },{
    title: 'Мои занятия',
    href: '/favorites',
    auth: true
  },{
    title: 'Галерея',
    href: '/gallary',
    auth: false
  }, {
    title: 'Контакты и отзывы',
    href: '/contacts',
    auth: false
  }
]