import styles from './styles.module.css'

const Description1 = ({ description1 }) => {
  if (!description1) { return null }
  return <div className={styles.description}>
    <h3 className={styles['description__title']}>Фамилия:</h3>
    <div className={styles['description__content']} dangerouslySetInnerHTML={{ __html: description1 }} />
  </div>
}

export default Description1

